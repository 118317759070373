.action-button {
  color: white;
  width: 159px;
  height: 44px;
  border-color: #2A61B4;
  background-image: linear-gradient(85deg, #0E2E5E 4.31%, #2758A2 87.92%);
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  padding: 24px 8px;
}

.action-button:hover {
  transition: all 0.3s;
  color: white;
  background-image: linear-gradient(85deg, #1E3A8A 4.31%, #3B82F6 87.92%);
}
