.modal-style .ant-modal-content {
    border-radius: 2px;
    background: #0B2031;
    box-shadow: 0px 24px 48.6px -8px rgba(0, 0, 0, 0.50);
  }

.modal-style .ant-modal-header {
    background: #0B2031;
    border: 0;
}

.modal-style .ant-modal-footer {
    border: 0;
}

.modal-style .ant-list-item-action {
    margin-left: 44px;
}

.modal-style .ant-list-header {
    position: sticky;
    z-index: 1;
    top: 0;
    background-color: #0B2031;
    border-bottom: 1px solid #054479;
    border-radius: 4px 4px 0 0;
}

.modal-style .ant-list-item-meta-title {
    font-size: 12px;
    font-weight: 400;
}

.modal-style .ant-list-item-meta-description {
    font-size: 12px;
    font-weight: 400;
    color: #0577B1;
}

.modal-style .ant-list-empty-text {
    display: none;
}

.modal-style .list {
    color: #fff;
    background-color: #0B2031;
    z-index: 1;
    width: 27.5%;
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 0;
    border-bottom: 0;
}

.modal-style .list-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 14px;
    padding-bottom: 14px;
    background-color: rgba(0, 0, 0, 0.2) !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    margin: 14px;
    height: 450px;
}

.modal-style .list-subgroup {
    padding: 9px 0px 0px 15px;
    font-size: 13px;
    font-weight: bold;
    color: #91E9E4;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0577B1;
    border-color: #0577B1;
}

.ant-btn-primary {
    color: #fff;
    background: #2758A2 !important;
    border-color: #2A61B4 !important;
}

.ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
    color: #fff !important;
    background: #2758A2 !important;
    border-color: #2A61B4 !important;
    text-shadow: none !important;
    box-shadow: none !important;
}


::-webkit-scrollbar{
  width: 12px;
}


::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}


::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}