.menu {
  border-bottom: 0;
  padding: 0;
}

.menu-item {
  color: #FFF;
  font-size: 18px;
  font-weight: 500;
  /* padding: 0 16px; */
  border-bottom: 0;
  transition: border-color 0.3s ease;
}

/* Target the specific menu item and submenu styles */
.menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #FBBF24 !important; /* Set your desired color here */
}

.menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after {
  border-bottom: 2px solid #FFF;
}

.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #FBBF24 !important;
}

.ant-menu-horizontal > .ant-menu-item-selected:hover a {
  color: #FBBF24 !important;
}

.ant-menu-horizontal > .ant-menu-item:hover a {
  color: #FFF;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #FFF;
}

.ant-menu-horizontal > .ant-menu-item a {
  color: #FFF;
}
